import Vue from 'vue'
import Vuex from 'vuex'
import rcg from '../data/rcg.js'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    rcg: JSON.parse(atob(rcg))
  }
})
