<template>
  <section>
    <h1 class="mx-auto mb-3" :id="page.properties.ID">{{ page.headline }}</h1>
    <div class="w-full">{{ page.body }}</div>
    <section
      v-for="contactSection in page.related"
      :key="contactSection.key"
      class="my-3 w-full"
    >
      <h3 class="text-left" :id="contactSection.properties.ID">
        {{ contactSection.headline }}
      </h3>
      <div class="w-full">{{ contactSection.body }}</div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']),
    page() {
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'Contact Us'])
    }
  }
}
</script>

<style scoped></style>
