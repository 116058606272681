<template>
  <section>
    <h1 class="mx-auto mb-3" :id="servicesPage.properties.ID">
      {{ servicesPage.headline }}
    </h1>
    <div>{{ servicesPage.body }}</div>

    <section
      v-for="service in servicesPage.related"
      :key="service.key"
      class="my-3"
    >
      <h3 class="text-left" :id="service.properties.ID">
        {{ service.headline }}
      </h3>
      <div>{{ service.body }}</div>
      <section
        v-for="serviceOption in service.related"
        :key="serviceOption.key"
        class="m-3"
      >
        <h4 class="text-left">{{ serviceOption.headline }}</h4>
        <div>{{ serviceOption.body }}</div>

        <ul class="mt-6">
          <li v-for="option in serviceOption.related" :key="option.key">
            <span>-</span> {{ option.headline }}
          </li>
        </ul>
      </section>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
    ...mapState(['rcg']),
    servicesPage() {
      return _.find(this.rcg, ['properties.NAVLINKNAME', 'Services'])
    }
  }
}
</script>

<style scoped></style>
