<template>
  <nav class="flex flex-wrap my-6 px-3 rcg-top items-baseline w-10/12 mx-auto">
    <router-link :to="{ name: 'Home' }" class="rcgHomeImg w-1/12" />
    <div
      class="my-auto flex flex-row divide-x bg-gray-200 inline leading-relaxed border border-black ml-4 w-10/12"
    >
      <section
        v-for="section in rcgFiltered"
        :key="section.key"
        class="border-gray-500 relative grow text-center"
      >
        <router-link
          :to="{
            name: section.properties.NAVLINKNAME,
            hash: '#' + section.properties.ID
          }"
          class="px-1"
          event
          @click.native="handleNav(section)"
        >
          <span>{{ section.properties.NAVLINKNAME }}</span>
          <i v-if="isDropDown(section)" class="material-icons align-middle"
            >arrow_drop_down</i
          >
        </router-link>
        <div
          class="flex flex-col absolute left-0 invisible border border-gray-600 bg-gray-300 w-full divide-y-2 text-left"
          :class="{ '!visible': openedDropDown === section.key }"
          v-if="isDropDown(section)"
        >
          <router-link
            v-for="dropDownPage in section.related"
            :key="dropDownPage.key"
            :to="{
              name: section.properties.NAVLINKNAME,
              hash: '#' + dropDownPage.properties.ID
            }"
            class="px-1 leading-loose"
            @click.native="openedDropDown = null"
          >
            {{ dropDownPage.properties.NAVLINKNAME }}
          </router-link>
        </div>
      </section>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  data() {
    return {
      openedDropDown: null
    }
  },
  computed: {
    ...mapState(['rcg']),
    rcgFiltered() {
      return _.filter(this.rcg, function (o) {
        return o.properties['NAVLINKTYPE'] !== 'Hidden'
      })
    }
  },
  methods: {
    isDropDown(section) {
      return section.properties.NAVLINKTYPE === 'Dropdown'
    },
    handleNav(section) {
      if (this.isDropDown(section)) {
        this.openedDropDown =
          this.openedDropDown === section.key ? null : section.key
      } else {
        this.openedDropDown = null
        if (this.$route.name !== section.properties.NAVLINKNAME)
          this.$router.push({ name: section.properties.NAVLINKNAME })
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/top.scss';
</style>
